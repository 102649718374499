/** @jsx jsx */
import React, { useState } from 'react';
import { jsx } from '@balance-web/core';
import { Button } from '@balance-web/button';
import { Heading } from '@balance-web/heading';
import { Flex } from '@balance-web/flex';
import { Text } from '@balance-web/text';
import { TextLink } from '@balance-web/text-link';
import { CloudOffIcon } from '@balance-web/icon';
import { Inline } from '@balance-web/inline';

import { CodeBlock } from '../CodeBlock';

export type ErrorScreenProps = {
  title?: string;
  image?: React.ReactNode;
  refreshButtonLabel?: string;
  children?: React.ReactNode;
  technical?: string;
  onRefreshClick?: () => void;
};

export const ErrorScreen = ({
  title = 'Oops, something went wrong',
  image,
  refreshButtonLabel = 'Refresh Page',
  onRefreshClick,
  technical,
  children,
}: ErrorScreenProps) => {
  const [technicalVisible, setTechnicalVisible] = useState(false);
  return (
    <Flex
      gap="xlarge"
      alignItems="center"
      justifyContent="center"
      flexGrow={1}
      direction="column"
    >
      {image || <CloudOffIcon color="dim" size="large" />}

      <Heading level="3" align="center">
        {title}
      </Heading>

      {children && (
        <Text size="small" color="muted">
          {children}
        </Text>
      )}
      <Inline gap="small">
        <Text size="small" color="muted">
          The issue may be temporary. Please try again. If the problem persists,
          get in touch with our friendly support team.
        </Text>
        <Text size="small" color="muted">
          <TextLink href="/settings/help-and-support">Learn more ›</TextLink>
        </Text>
      </Inline>

      {technical && (
        <Flex gap="small" direction="column" alignItems="center">
          <Button
            label={`${(!technicalVisible && 'show') || 'hide'} details`}
            size="small"
            weight="none"
            onClick={() => setTechnicalVisible(!technicalVisible)}
          />
          {(technicalVisible && <CodeBlock code={technical} />) || null}
        </Flex>
      )}

      {(onRefreshClick && (
        <Button
          onClick={onRefreshClick}
          size="medium"
          label={refreshButtonLabel}
          block
        />
      )) ||
        null}
    </Flex>
  );
};
