import React, { useMemo } from 'react';

import { useAuthenticatedUser } from '../../../services/Auth';
import { ErrorScreen } from '../../common/ErrorScreen';
import { PortalURLs } from '../../../constants';

import { useGetAppDirectoryQuery } from './useGetAppDirectoryQuery';
import { AppSelector } from './AppSelector';

type AppSelectorContainerProps = {
  onAddProductsToAccountClick: (url: string) => void;
};

export function AppSelectorContainer({
  onAddProductsToAccountClick,
}: AppSelectorContainerProps) {
  const { user } = useAuthenticatedUser();
  const {
    apps,
    isLoading,
    error: getAppDirectoryError,
    refetchApps,
  } = useGetAppDirectoryQuery({ userId: `${user?.id}` });

  const missingProductHelpURL: string = useMemo(() => {
    if (!user || !user.tenancyId) {
      return '';
    }

    return PortalURLs[user.tenancyId];
  }, [user]);

  const handleAddProductsToAccountClick = () => {
    onAddProductsToAccountClick(PortalURLs[user?.tenancyId!]);
  };

  if (getAppDirectoryError)
    return (
      <ErrorScreen
        title="Problem loading app directory"
        onRefreshClick={refetchApps}
      />
    );

  return (
    <AppSelector
      apps={apps}
      isLoading={isLoading}
      missingProductHelpURL={missingProductHelpURL}
      onAddProductsToAccountClick={handleAddProductsToAccountClick}
    />
  );
}
