// ts-gql-integrity:ed82883b833b40f1dbea1e19c5786789
/*
ts-gql-meta-begin
{
  "hash": "b7f5365f521d2660d9b3d64bcc6eb75d"
}
ts-gql-meta-end
*/

import * as SchemaTypes from "./@schema";
import { TypedDocumentNode } from "@ts-gql/tag";

type GetUserAccessedAppsQueryQueryVariables = {};


type GetUserAccessedAppsQueryQuery = (
  { readonly __typename: 'Query' }
  & { readonly authenticatedUser: SchemaTypes.Maybe<(
    { readonly __typename: 'User' }
    & Pick<SchemaTypes.User, 'id'>
    & { readonly userProducts: SchemaTypes.Maybe<ReadonlyArray<(
      { readonly __typename: 'ProductTypeDetails' }
      & Pick<SchemaTypes.ProductTypeDetails, 'productType' | 'url' | 'logoURL'>
    )>> }
  )> }
);


export type type = TypedDocumentNode<{
  type: "query";
  result: GetUserAccessedAppsQueryQuery;
  variables: GetUserAccessedAppsQueryQueryVariables;
  documents: SchemaTypes.TSGQLDocuments;
}>

declare module "./@schema" {
  interface TSGQLDocuments {
    GetUserAccessedAppsQuery: type;
  }
}

export const document = JSON.parse("{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"query\",\"name\":{\"kind\":\"Name\",\"value\":\"GetUserAccessedAppsQuery\"},\"variableDefinitions\":[],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"authenticatedUser\"},\"arguments\":[],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"},\"arguments\":[],\"directives\":[]},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"userProducts\"},\"arguments\":[],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"productType\"},\"arguments\":[],\"directives\":[]},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"url\"},\"arguments\":[],\"directives\":[]},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"logoURL\"},\"arguments\":[],\"directives\":[]}]}}]}}]}}]}")
